"use client";

import { useDonationTimeSerie } from "@/hooks/useDonationTimeSerie";
import { Donations } from "@/types/models/Donations";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { currency } from "@/lib/format";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { useMemo, useState } from "react";
import { cn } from "@/lib/utils";

import {
  addMonths,
  addWeeks,
  startOfMonth,
  startOfWeek,
  format,
  differenceInMonths,
  differenceInWeeks,
  getDate,
} from "date-fns";
import { useAccount } from "@/hooks/useAccount";

function groupDonationsByInterval(
  donations: Donations[],
  interval: "month" | "week",
  from?: Date
): { name: string; total: number }[] {
  const getWeekLabel = (date: Date) => {
    const weekStart = startOfWeek(new Date(date), { weekStartsOn: 1 });

    return `${format(date, "MMM")} ${getDate(weekStart)}`;
  };
  // Create an array of dates spanning the desired range
  const dates = [];
  const now = new Date();

  if (interval === "month") {
    const startDate =
      from && differenceInMonths(now, from) > 6 ? from : addMonths(now, -6);
    const futureDate = addMonths(now, 3);
    const numMonths = differenceInMonths(futureDate, startDate);

    for (let i = 0; i <= numMonths; i++) {
      dates.push(addMonths(startDate, i));
    }
  } else if (interval === "week") {
    const startDate =
      from && differenceInWeeks(now, from) > 6 ? from : addWeeks(now, -6);
    const futureDate = addWeeks(now, 4);
    const numWeeks = differenceInWeeks(futureDate, startDate);

    for (let i = 0; i <= numWeeks; i++) {
      dates.push(addWeeks(startDate, i));
    }
  }

  for (let date of dates) {
    console.log(getWeekLabel(date));
  }

  // Reduce the donations into groups by date
  const grouped = dates.map((date) => {
    const dateKey =
      interval === "month"
        ? format(startOfMonth(date), "MMM yyyy")
        : getWeekLabel(date);

    // Find the donations for this date
    const donationsForDate = donations.filter((donation) => {
      const donationDateKey =
        interval === "month"
          ? format(startOfMonth(donation.createdAt), "MMM yyyy")
          : getWeekLabel(donation.createdAt);
      return donationDateKey === dateKey;
    });

    // Calculate the total for this date
    const total = donationsForDate.reduce(
      (total, donation) => total + donation.grossAmount / 100,
      0
    );

    return { name: dateKey, total };
  });
  console.log(grouped);
  return grouped;
}
export function Chart() {
  const { account } = useAccount();
  const { donations, isLoading } = useDonationTimeSerie({});
  const [view, setView] = useState<"month" | "week">("month");
  const data = useMemo(
    () => groupDonationsByInterval(donations, view, account?.createdAt),
    [account, donations, view]
  );

  const noData = useMemo(() => {
    if (!donations.length && !isLoading) {
      return true;
    }
    return false;
  }, [donations, isLoading]);

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle className="text-lg">Volume</CardTitle>
        {!noData && (
          <ToggleGroup
            type="single"
            variant={"outline"}
            onValueChange={(v) => setView(v as "month" | "week")}
            value={view}
          >
            <ToggleGroupItem
              value="month"
              aria-label="Month view"
              className="h-8"
            >
              Month
            </ToggleGroupItem>
            <ToggleGroupItem
              value="week"
              aria-label="Week view"
              className="h-8"
            >
              Week
            </ToggleGroupItem>
          </ToggleGroup>
        )}
      </CardHeader>
      <CardContent className="relative overflow-visible">
        <ResponsiveContainer width="100%" height={350}>
          <BarChart data={noData ? DEMO_DATA : data}>
            <XAxis
              dataKey="name"
              stroke="#888888"
              fontSize={12}
              tickLine={false}
              axisLine={true}
              angle={view === "month" ? 0 : -30}
              tickMargin={view === "month" ? 0 : 12}
            />
            <YAxis
              stroke="#888888"
              fontSize={12}
              tickLine={false}
              axisLine={true}
              tickFormatter={(value) => `${currency(value)}`}
            />
            <Bar
              dataKey="total"
              fill="currentColor"
              radius={[4, 4, 0, 0]}
              className={cn("fill-primary", { "fill-primary/5": noData })}
            />
          </BarChart>
        </ResponsiveContainer>
        {noData && (
          <div className="absolute inset-0 flex items-center justify-center">
            <p className="text-lg text-muted-foreground p-6 bg-background border shadow rounded">
              No data available yet
            </p>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

const DEMO_DATA = [
  {
    name: "Jan",
    total: Math.floor(Math.random() * 5000) + 1000,
  },
  {
    name: "Feb",
    total: Math.floor(Math.random() * 5000) + 1000,
  },
  {
    name: "Mar",
    total: Math.floor(Math.random() * 5000) + 1000,
  },
  {
    name: "Apr",
    total: Math.floor(Math.random() * 5000) + 1000,
  },
  {
    name: "May",
    total: Math.floor(Math.random() * 5000) + 1000,
  },
  {
    name: "Jun",
    total: Math.floor(Math.random() * 5000) + 1000,
  },
  {
    name: "Jul",
    total: Math.floor(Math.random() * 5000) + 1000,
  },
  {
    name: "Aug",
    total: Math.floor(Math.random() * 5000) + 1000,
  },
  {
    name: "Sep",
    total: Math.floor(Math.random() * 5000) + 1000,
  },
  {
    name: "Oct",
    total: Math.floor(Math.random() * 5000) + 1000,
  },
  {
    name: "Nov",
    total: Math.floor(Math.random() * 5000) + 1000,
  },
  {
    name: "Dec",
    total: Math.floor(Math.random() * 5000) + 1000,
  },
];
