import { DonationsResponse } from "@/services/donations.service";
import { routes } from "@/services/donations.service";
import useSWR from "swr";
import { fetcherV2 } from "@/services/api";
import { Donations } from "@/types/models/Donations";

interface Props {
  campaignId?: number;
}

export function useDonationTimeSerie({ campaignId }: Props) {
  const { data, error, mutate, isLoading } = useSWR(
    () => [routes.timeSerie, { campaignId }],
    fetcherV2<Donations[]>,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    donations: data ?? [],
    isLoading,
  };
}
