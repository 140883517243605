import { CDN_URL } from "@/config/cdn";
import { Account } from "@/types/models/Account";

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getWidgetUrl = (account: Account) => {
  switch (process.env.NEXT_PUBLIC_STAGE) {
    case "dev":
      return `http://localhost:10000/form/${account.id}`;
    case "prod":
      return `${account.website}?show-form=true`;
    default:
      return undefined;
  }
};

export function getDifferentKeys(obj1: any, obj2: any) {
  const allKeys = new Set([
    ...Object.keys(obj1 ?? {}),
    ...Object.keys(obj2 ?? {}),
  ]);
  const differentKeys = [];

  for (const key of allKeys) {
    if (obj1[key] !== obj2[key]) {
      differentKeys.push(key);
    }
  }

  return differentKeys;
}

export function generateFilenameWithDateTime(
  filename: string,
  extension: string,
): string {
  const date = new Date();
  const dateString = date.toISOString().replace(/:/g, "-").slice(0, 19);
  return `${filename}_${dateString}.${extension}`;
}

export function getMonthName(index: number): string {
  const date = new Date();
  date.setMonth(index);
  return new Intl.DateTimeFormat("en-AU", { month: "long" }).format(date);
}

export const convertImagePathToCDN = (url: string) => {
  const path = new URL(url).pathname.slice(1);
  return `${CDN_URL}/${path}`;
};

export function capitalize(sentence: string): string {
  const words = sentence.split(" ");
  const capitalizedWords = words.map((word) => capitalizeText(word));
  return capitalizedWords.join(" ");
}

function capitalizeText(text: string): string {
  if (text.length === 0) {
    return text; // Return empty string for empty input
  }

  const firstLetter = text.charAt(0).toUpperCase();
  const restOfText = text.slice(1).toLowerCase();

  return firstLetter + restOfText;
}
