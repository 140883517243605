import { AccountStats } from "@/services/account.service";
import { routes } from "@/services/account.service";
import { fetcher } from "@/lib/apiFetch";
import useSWR from "swr";
import { fetcherV2 } from "@/services/api";

interface Props {
  startDate?: Date;
  endDate?: Date;
}
export function useAccountStats(query: Props) {
  const { data, error } = useSWR(
    () => [routes.getStats, query, true],
    fetcherV2<AccountStats>,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    accountStats: data,
    isLoading: !error && !data,
    isError: error,
  };
}
